import React from 'react';
import { ReportControlContext } from 'modules/reports/index';
import { Controls } from 'modules/orders/components';
import { OrdersConfigProvider } from 'modules/orders/providers';
import { OrdersRoot } from 'modules/orders/containers/OrdersRoot/OrdersRoot';
import { faArrowRightToArc } from '@fortawesome/pro-light-svg-icons';

export const RealReRequest = (props: ReportControlContext) => {
  const { data: { orders } } = props;

  return (
    <OrdersConfigProvider>
      <OrdersRoot>
        <Controls.RealReRequest
          button={undefined}
          icon={faArrowRightToArc}
          data={{ orders, doctor: orders[0]?.doctor }}
          context={{ selectedOrder: 0 }}
        />
      </OrdersRoot>
    </OrdersConfigProvider>
  );

};
