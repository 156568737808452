import React from 'react';
import { OrderControlContext } from 'modules/orders/index';
import { faPlusCircle } from '@fortawesome/pro-regular-svg-icons';
import messages from 'messages';
import { useStartRealReRequest } from 'modules/orders/providers';
import { ChooseOrdersControl } from 'modules/orders/components/ChooseOrdersControl';
import { ControlProps } from 'components';

export type RealReRequestControlProps = OrderControlContext & Partial<ControlProps>;

const labels = messages.orders.controls.reRequest;

export const RealReRequest: React.FC<RealReRequestControlProps> = ({ data, context, ...controlProps }) => {

  const { orders, hasMissingAggregation } = data;
  const { selectedOrder } = context;

  const startRealRequest = useStartRealReRequest();

  return (
    <ChooseOrdersControl
      icon={faPlusCircle}
      button={{
        type: 'primary',
        block: true,
      }}
      single
      defaultSelectedOrder={selectedOrder}
      disabledCheck={o => !o.realReRequestConditions?.allowed}
      disabled={hasMissingAggregation}
      orders={orders}
      label={labels.label}
      onDone={orders => startRealRequest(data, orders.map(o => o.id))}
      {...controlProps}
    />
  );
};
