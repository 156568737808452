import React, { forwardRef, RefCallback, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { InputRef } from 'antd';
import { useTranslate } from 'providers';
import { useIMask } from 'react-imask';
import { type InputMask } from 'imask';
import { default as AntInput } from 'antd/es/input';
import { InputProps } from '../Input';

export const MaskedInput = forwardRef<InputRef, InputProps>((props, ref) => {

  const translate = useTranslate();

  const { format, placeholder, ...rest } = props;

  const [isFocused, setIsFocused] = useState(props.autoFocus);

  const inputRef = useRef(null);

  useImperativeHandle(ref, () => {
    return inputRef.current.element;
  }, []);

  const { value, setValue } = useIMask({
    ...props.mask,
    lazy: !isFocused,
    placeholderChar: '#',
  }, {
    ref: inputRef,
    onComplete: (value: string, maskRef: InputMask) => {
      if (props.value !== value) {
        props.onChange({ target: { value: maskRef.unmaskedValue } } as React.ChangeEvent<HTMLInputElement>);
      }
    },
  });

  useEffect(() => {
    setValue(props.value as string);
  }, [props.value]);

  const onFocus: React.FocusEventHandler<HTMLInputElement> = useCallback((event) => {
    setIsFocused(true);
    props.onFocus?.(event as any);
    props.onFocus?.(event as any);
  }, [props.onFocus]);

  const onBlur: React.FocusEventHandler<HTMLInputElement> = useCallback((event) => {
    setIsFocused(false);
    props.onBlur?.(event as any);
    props.onBlur?.(event as any);
  }, [props.onBlur]);

  const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => setValue(e.target.value), []);

  const setRef = useCallback<RefCallback<InputRef>>(r => inputRef.current = r?.input, []);

  return (
    <AntInput
      {...rest}
      value={value}
      ref={setRef}
      placeholder={translate(placeholder)}
      onFocus={onFocus}
      onBlur={onBlur}
      onChange={onChange}
    />
  );
});
