export default {
  label: 'Categories',
  fields: {
    name: 'Category name',
    parent: 'Parent category',
    path: 'Path',
    isPublic: 'Public',
    included_doctors: 'Included doctors',
    excluded_doctors: 'Excluded doctors',
  },
  permissionType: {
    public: 'Public',
    private: 'Private',
  },
  controls: {
    add: {
      label: 'Add category',
    },
    edit: {
      label: 'Edit category',
    },
    delete: {
      label: 'Delete category',
      confirm: 'Confirm delete',
    },
  },
};
