import React, { useMemo, useState } from 'react';
import { AutoComplete as AntAutoComplete } from 'antd';
import { useEnv } from 'providers/EnvProvider';
import { Message } from 'interfaces';
import { AnyFunction, arrayify } from 'utils/helpers';
import messages from 'messages';
import { filter, isFunction, map } from 'lodash';
import { Translate, useApi } from 'providers';
import { AutocompleteFilter, AutocompleteItem, SelectedAutocompleteFilter, SelectedAutocompleteGroupFilter } from 'components/Search/Search';
import { Filter } from 'components/Filters';
import { AdvancedAnalysisFilterType, Doctor, Patient } from 'interfaces/api';
import dayjs from 'dayjs';
import { useDebounce } from 'react-use';

export type AutocompleteProps = {
  filters?: AutocompleteFilter[];
  dateFilters?: Filter[];
  groups?: Record<string, AutoCompleteGroup | AutoCompleteGroupCallback>;
  request?: (query: string) => Promise<Record<string, any[]>>;
  defaultQuery?: boolean;
  lanr?: boolean;
} & {
  [G in keyof typeof AutoCompleteGroups]?: boolean;
};

export type SearchAutoCompleteProps = AutocompleteProps & {
  query: string;
  onSelect: (value: string) => void;
  onSearch: (query: string) => void;
  children: React.ReactNode;
};

const autocompleteLabels = messages.general.search.autocomplete;

export type AutoCompleteGroupCallback = (items: any) => {
  label: Message;
  options: AutocompleteFilter[];
};

export type AutoCompleteGroup = {
  label: Message;
  filter?: AnyFunction;
  getTitle: (item: any) => React.ReactNode | string;
  getMeta?: (item: any) => React.ReactNode | string;
  wrapLabel?: (item: any, content: React.ReactNode) => React.ReactNode;
};

const patientsAutoCompleteGroup: AutoCompleteGroup = {
  label: autocompleteLabels.groups.patients,
  filter: (entity?: Patient) => ({ pid: entity?.pid }),
  getTitle: (entity: Patient) => entity.displayName,
  getMeta: (entity: Patient) => dayjs(entity.birthday).format('L'),
};

const doctorsAutoCompleteGroup: AutoCompleteGroup = {
  label: autocompleteLabels.groups.doctors,
  filter: (entity?: Doctor) => ({ aid: entity?.aid, lanr: entity?.lanr }),
  getTitle: (entity: Doctor) => entity.displayName,
  getMeta: (entity: Doctor) => entity?.lanr || entity.externalId,
};

const analysesAutoCompleteGroup: AutoCompleteGroupCallback = (items: string[]) => ({
  label: autocompleteLabels.groups.analyses,
  options: [{
    label: autocompleteLabels.requirement.label,
    queryLabelRender: (name: string) => <Translate message={autocompleteLabels.requirement.exists} values={{ name: name.toUpperCase() }}/>,
    filter: (name: string) => ({ requirement: { name: name.toUpperCase(), filterType: AdvancedAnalysisFilterType.Exists } }),
  }, {
    label: autocompleteLabels.requirement.label,
    queryLabelRender: (name: string) => <Translate message={autocompleteLabels.requirement.pathological} values={{ name: name.toUpperCase() }}/>,
    filter: (name: string) => ({ requirement: { name: name.toUpperCase(), filterType: AdvancedAnalysisFilterType.Patho } }),
  }, {
    label: autocompleteLabels.requirement.label,
    queryLabelRender: (name: string) => <Translate message={autocompleteLabels.requirement.extreme} values={{ name: name.toUpperCase() }}/>,
    filter: (name: string) => ({ requirement: { name: name.toUpperCase(), filterType: AdvancedAnalysisFilterType.Extreme } }),
  }],
});

export const AutoCompleteGroups = {
  patients: patientsAutoCompleteGroup,
  doctors: doctorsAutoCompleteGroup,
  analyses: analysesAutoCompleteGroup,
};

const getValidDateFromQuery = (query: string) => {
  const ddmmyyyy = query.match(/^(0[1-9]|[12][0-9]|3[01])(?:[-/.])?(?:(0[1-9]|1[012])(?:[-/.]?))?((?:19|20)\d\d)?$/);
  if (ddmmyyyy) {
    return dayjs((ddmmyyyy[3] || dayjs().year()) + '-' + (ddmmyyyy[2] || dayjs().format('MM')) + '-' + ddmmyyyy[1]);
  }

  const yyyymmdd = query.match(/^((?:19|20)\d\d)(?:[-/.])?(?:(0[1-9]|1[012])(?:[-/.]?))?(0[1-9]|[12][0-9]|3[01])?$/);
  if (yyyymmdd) {
    return dayjs((yyyymmdd[3] || dayjs().year()) + '-' + (yyyymmdd[2] || dayjs().format('MM')) + '-' + yyyymmdd[1]);
  }

  return null;
};

export const AutoComplete: React.FC<SearchAutoCompleteProps> = React.memo((props) => {

  const { query, patients, doctors, defaultQuery, filters, dateFilters, analyses } = props;

  const isWeb = useEnv.isWeb();

  const { globals: { getAutocomplete } } = useApi();

  const [autocompleteItems, setAutocompleteItems] = useState<Record<string, any[]>>();

  const loadItems = () => {
    if (query.length >= 1) {
      const { request, patients, doctors, lanr, analyses: analysesShort } = props;

      (
        request
          ? request(query)
          : getAutocomplete({
            query,
            patients,
            doctors,
            lanr,
            analysesShort,
          })
      ).then(setAutocompleteItems).catch();
    } else {
      setAutocompleteItems(undefined);
    }
  };

  useDebounce(loadItems, 250, [query]);

  const options = useMemo(() => {

    if (query.length === 0) {
      return [];
    }

    const groups = {
      patients: patients ? AutoCompleteGroups.patients : undefined,
      doctors: doctors ? AutoCompleteGroups.doctors : undefined,
      analyses: analyses ? AutoCompleteGroups.analyses : undefined,
      ...props.groups,
    };

    const renderFilter = (label: Message, query: string, queryNode: React.ReactNode, filter: any) => ({
      label: <AutocompleteItem label={label} value={queryNode}/>,
      value: JSON.stringify({ query, filter, label } as SelectedAutocompleteFilter),
    });

    const renderQueryFilter = (filter: AutocompleteFilter) => {
      const matchedQuery = arrayify(filter.regex ? query.match(filter.regex) : query);
      return renderFilter(filter.label, query, filter.queryLabelRender?.(...matchedQuery) || `${query}...`, filter.filter(...matchedQuery));
    };

    let options: any = [];

    if (defaultQuery) {
      options.push(renderQueryFilter({
        label: messages.general.search.autocomplete.default,
        filter: query => ({ query }),
      }));
    }

    options = options.concat(arrayify(filter(filters, f => !f.regex || query.match(f.regex))).map(renderQueryFilter));

    const dateQuery = getValidDateFromQuery(query);

    if (dateQuery && dateFilters?.length > 0) {
      options = options.concat({
        label: <span className={'autocomplete-group-header'}><Translate message={messages.general.search.dateSearch}/></span>,
        options: arrayify(dateFilters).map(filter => renderFilter(filter.label, dateQuery.format('L'), dateQuery.format('L'), filter.filter(dateQuery.format('YYYY-MM-DD')))),
      });
    }

    options = options.concat(filter(map(autocompleteItems || {}, (children, group) => {

      // @ts-expect-error todo
      const autocompleteGroup = groups[group];

      if (children.length === 0 || !autocompleteGroup) {
        return null;
      }

      const wrapHeader = (message: Message) => (
        <span className={'autocomplete-group-header'}>
          <Translate message={message}/>
        </span>
      );

      if (isFunction(autocompleteGroup)) {
        const result = autocompleteGroup(children);
        return { label: wrapHeader(result.label), options: result.options.map(renderQueryFilter) };
      }

      const wrapLabel = (item: any, content: React.ReactElement) => {
        return autocompleteGroup.wrapLabel?.(item, content) || content;
      };

      return {
        label: wrapHeader(autocompleteGroup.label),
        options: children.map(item => ({
          label: wrapLabel(item, (
            <span className={'autocomplete-label'}>
              <span className={'autocomplete-title'}>
                {autocompleteGroup.getTitle(item)}
              </span>
              {autocompleteGroup.getMeta && (
                <span className={'autocomplete-subtitle'}>
                  {autocompleteGroup.getMeta(item)}
                </span>
              )}
            </span>
          )),
          value: JSON.stringify({
            group,
            item,
            filter: autocompleteGroup.filter ? autocompleteGroup.filter(item) : undefined,
          } as SelectedAutocompleteGroupFilter),
        })),
      };

    })));

    return options;
  }, [autocompleteItems, query]);

  return (
    <AntAutoComplete
      autoFocus={isWeb}
      value={query}
      options={options}
      popupClassName={'autocomplete'}
      onSelect={props.onSelect}
      onSearch={props.onSearch}
      popupMatchSelectWidth={false}
      defaultActiveFirstOption={true}
    >
      {props.children}
    </AntAutoComplete>
  );

});
