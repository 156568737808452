import { filter } from 'lodash';
import { Product } from 'interfaces/api';
import { useCallback } from 'react';
import { useGuard } from 'containers';
import { useLocation } from 'react-router';
import { useAuthStoreSelectors } from 'modules/auth/store';
import { useNavigate } from 'react-router-dom';

export const useRedirectStartProduct = () => {

  const guard = useGuard();
  const navigate = useNavigate();
  const { state: locationState } = useLocation();

  const user = useAuthStoreSelectors.user();

  return useCallback(() => {

    if (user) {
      const availableProducts = filter(Product, product => !!guard({ product }, () => product));

      const startProductKey = user.preferences.startProduct || availableProducts[0];
      const product = guard({ product: startProductKey }, () => startProductKey) || availableProducts[0];

      const redirectTo = locationState?.redirectTo;

      navigate(redirectTo && redirectTo !== '/' ? redirectTo : '/' + product, { replace: true });
    }

  }, [user, navigate, locationState, guard]);

};
