import { StoreOrderStatus } from 'interfaces/api';
import { Color } from 'interfaces';

export const StoreOrderStatusColor = {
  [StoreOrderStatus.Cancelled]: Color.LightGray,
  [StoreOrderStatus.Draft]: Color.LightGray,
  [StoreOrderStatus.Ordered]: Color.Gray,
  [StoreOrderStatus.Commissioning]: Color.Yellow,
  [StoreOrderStatus.Completed]: Color.Green,
};
