import React from 'react';
import messages from 'messages';
import { FormItemRenderProps } from 'components/Form/Item';
import { OrdersPreferencesProperties } from 'interfaces/api';

const labels = messages.admin.order.general;

export const OrdersBakPreferencesForm: React.ComponentType<FormItemRenderProps<Required<OrdersPreferencesProperties>>> = (props) => {

  const { Switch, ColorPicker } = props;

  return (
    <>

      <Switch
        property={'orderWizardBakModalBlockButtons'}
        label={labels.bakModalBlockButtons.label}
        description={labels.bakModalBlockButtons.description}
      />

      <ColorPicker
        property={'orderWizardBakColor'}
        label={labels.bakSettings.bakColor}
      />

      <ColorPicker
        property={'orderWizardBakFontColor'}
        label={labels.bakSettings.bakFontColor}
      />

    </>
  );

};
