import React, { useCallback, useMemo } from 'react';
import { withContent, WithContentInnerProps, WithContentOuterProps } from 'hocs';
import {
  AutoCompleteGroups,
  Container,
  Control,
  Icon,
  isAutocompleteFilter,
  isAutocompleteGroupFilter,
  Search,
  SearchProps,
  SelectedAutocomplete,
  SideBar,
  SideBarIconNav,
  Tooltip,
} from 'components';
import { Breakpoint, Message } from 'interfaces';
import { Translate } from 'providers';
import { ListLayoutArgs, ListLayoutContext, ListLayoutMode } from 'containers';
import cx from 'classnames';
import styles from './SideBar.module.less';
import { faInfoCircle, faTimesCircle } from '@fortawesome/pro-regular-svg-icons';
import { CSSTransition } from 'react-transition-group';
import { Toggle } from 'hooks';
import { filter, merge } from 'lodash';
import messages from 'messages';
import { DoctorAutocomplete, PatientAutocomplete } from './AutoComplete';
import { DoctorHierarchySection } from 'containers/ListLayout/SideBar/DoctorHierarchy/DoctorHierarchy';

export type ListLayoutSideBarSectionProps<Context> = {
  title?: Message;
  info?: Message;
  extra?: React.ComponentType<ListLayoutArgs<Context>>;
  className?: string;
} & WithContentOuterProps<ListLayoutArgs<Context>> & Partial<ListLayoutArgs<Context>>;

export type ListLayoutSideBarHiddenSectionProps<Context> = ListLayoutSideBarSectionProps<Context> & {
  active?: boolean;
};

export type ListLayoutSideBarPanelProps<Context> = {
  title?: Message;
  extras?: React.ComponentType<ListLayoutArgs<Context>>[];
  active?: boolean;
} & WithContentOuterProps<ListLayoutArgs<Context>>;

export type ListLayoutSideBarButtonProps<Context> = WithContentOuterProps<ListLayoutArgs<Context>>;

export type ListLayoutSideBarSectionCallback<Context> = (args: ListLayoutArgs<Context>) => ListLayoutSideBarSectionProps<Context>;
export type ListLayoutSideBarHiddenSectionCallback<Context> = (args: ListLayoutArgs<Context>) => ListLayoutSideBarHiddenSectionProps<Context>;
export type ListLayoutSideBarPanelCallback<Context> = (args: ListLayoutArgs<Context>) => ListLayoutSideBarPanelProps<Context>;
export type ListLayoutSideBarButtonCallback<Context> = (args: ListLayoutArgs<Context>) => ListLayoutSideBarButtonProps<Context>;

export type ListLayoutSideBarProps<Context extends ListLayoutContext> = {
  toggle?: Toggle;
  breakpoint?: Breakpoint;
  modeSection?: {
    title: Message;
    modes: ListLayoutMode<Context>[];
  };
  doctorHierarchy?: boolean;
  sections?: ListLayoutSideBarSectionCallback<Context>[];
  frontSections?: ListLayoutSideBarSectionCallback<Context>[];
  hiddenSections?: ListLayoutSideBarHiddenSectionCallback<Context>[];
  buttons?: ListLayoutSideBarButtonCallback<Context>[];
  panels?: ListLayoutSideBarPanelCallback<Context>[];
};

export type ListLayoutSideBarSearchProps = {
  search?: Partial<SearchProps>;
  onSearch: (value: any, reset: () => void) => void;
  onAutocomplete?: (selected: SelectedAutocomplete) => void;
};

type Props<Context> = ListLayoutArgs<Context> & ListLayoutSideBarProps<Context> & ListLayoutSideBarSearchProps;

export const AutocompleteTag = <Context extends ListLayoutContext>(props: ListLayoutArgs<Context> & { onAutocomplete?: (selected: SelectedAutocomplete) => void }) => {

  const { autocomplete } = props.context;
  const autoCompleteSectionProps: ListLayoutSideBarSectionProps<Context> = {};

  const autoCompleteExtra = () => (
    <Control
      label={messages.general.reset}
      icon={faTimesCircle}
      tooltip={{ placement: 'right' }}
      onClick={() => {
        props.bindings.setContext({ autocomplete: undefined } as Context, true);
        props.onAutocomplete?.(undefined);
      }}
    />
  );

  if (isAutocompleteFilter(autocomplete)) {
    autoCompleteSectionProps.title = autocomplete.label;
    autoCompleteSectionProps.children = <span className={cx(styles.autocomplete, 'autocomplete-result-value')}>{autocomplete.query}</span>;
  } else if (isAutocompleteGroupFilter(autocomplete)) {
    // @ts-expect-error todo
    const group = AutoCompleteGroups[autocomplete.group];
    if (autocomplete.group === 'patients') {
      autoCompleteSectionProps.children = <PatientAutocomplete id={group.filter(autocomplete.item).pid} reset={autoCompleteExtra()}/>;
    } else if (autocomplete.group === 'doctors') {
      autoCompleteSectionProps.children = <DoctorAutocomplete id={group.filter(autocomplete.item).aid} reset={autoCompleteExtra()}/>;
    }
  }

  return (<ListLayoutSideBarSection {...autoCompleteSectionProps} {...props} extra={autoCompleteExtra} className={'autocomplete-result-tag'}/>);
};

export const ListLayoutSideBar = <Context extends ListLayoutContext>(props: Props<Context>) => {

  const { toggle, buttons, panels, context, breakpoint, hiddenSections, bindings } = props;

  const { autocomplete } = context;
  const { setContext } = bindings;

  const args = { context, bindings };

  const activeHiddenSection = (hiddenSections || []).filter(s => s(args).active).length > 0;

  const modeSection = useCallback(() => props.modeSection && ({
    title: props.modeSection.title,
    component: () => (
      <SideBarIconNav
        items={props.modeSection.modes.map(mode => ({
          ...mode,
          active: context.mode === mode.type,
          onClick: () => {
            setContext({ mode: mode.type, ...mode.onSelect?.(args) } as Context, true);
            bindings.setListView();
          },
        }))}
      />
    ),
  }), [props.modeSection, context.mode]);

  const allSections = useMemo(() => filter([
    modeSection,
    ...(props.sections || []),
  ]), [modeSection, props.sections]);

  const renderedButtons = useMemo(() => buttons && (
    <Container className={styles.buttons}>
      <ul>
        {buttons.map((b, idx) => (
          <li key={idx}>
            <ListLayoutSideBarButton {...args} {...b({ context, bindings })}/>
          </li>
        ))}
      </ul>
    </Container>
  ), [buttons]);

  const search = useMemo(() => merge({
    onSearch: props.onSearch,
    onAutocomplete: props.onAutocomplete,
  }, props.search), [props.search, props.onSearch, props.onAutocomplete]);

  const topContent = useMemo(() => (
    <Container shrink>
      <Search {...search} className={styles.search}/>
    </Container>
  ), [search]);

  return (
    <SideBar
      toggle={toggle}
      breakpoint={breakpoint || Breakpoint.DesktopDown}
      topContent={props.search ? topContent : undefined}
    >

      <Container reset grow>

        {((allSections || []).length > 0 || autocomplete) && (
          <Container className={styles.filterPadding}>
            {autocomplete && <AutocompleteTag {...args} onAutocomplete={search.onAutocomplete}/>}
            {allSections.map((section, index) => (
              <ListLayoutSideBarSection key={index} {...section(args)} {...args}/>
            ))}
          </Container>
        )}

        <Container reset horizontal grow className={cx(styles.filterWrapper, { [styles.filtersActive]: activeHiddenSection })}>

          <Container reset grow className={styles.filterWrapperLeft}>
            <Container scrollY grow shrink>
              <div className={styles.filterPadding}>
                {filter(props.frontSections || []).map((section, index) => <ListLayoutSideBarSection key={index} {...section(args)} {...args}/>)}
                {props.doctorHierarchy && <DoctorHierarchySection {...args}/>}
              </div>
            </Container>
          </Container>

          <Container reset grow className={styles.filterWrapperRight}>
            <Container scrollY grow shrink>
              <div className={styles.filterPadding}>
                {filter(hiddenSections || []).map((section, index) => {
                  const sectionProps = section(args);
                  return sectionProps.active
                    ? (
                      <ListLayoutSideBarSection
                        key={index}
                        {...sectionProps}
                        {...args}
                      />
                    )
                    : null;
                })}
              </div>
            </Container>
          </Container>

        </Container>

        {renderedButtons}

        {(filter(panels) || []).map((panel, index) => (
          <CSSTransition key={index} in={panel({ context, bindings }).active} timeout={200} classNames={'reveal-bottom'} mountOnEnter unmountOnExit>
            <ListLayoutSideBarPanel {...args} {...panel({ context, bindings })}/>
          </CSSTransition>
        ))}

      </Container>

    </SideBar>
  );
};

// eslint-disable-next-line @stylistic/comma-dangle
export const ListLayoutSideBarSection = withContent(React.memo(<Context, >(props: ListLayoutSideBarSectionProps<Context> & WithContentInnerProps<ListLayoutArgs<Context>>) => {

  const { title, info, extra: ExtraComponent, context, bindings, className } = props;

  const content = props.renderContent({ context, bindings });

  if (!content) {
    return null;
  }

  return (
    <Container grow shrink className={cx(styles.section, className, 'list-layout-sidebar-section')}>

      {title && (
        <h3>
          <span><Translate message={title}/></span>
          {info && (
            <a>
              <Tooltip placement={'right'} title={info}>
                <Icon icon={faInfoCircle}/>
              </Tooltip>
            </a>
          )}
          {ExtraComponent && <ExtraComponent context={context} bindings={bindings}/>}
        </h3>
      )}

      {content}

    </Container>
  );
}));

export const ListLayoutSideBarPanel = withContent(React.memo(
  // eslint-disable-next-line @stylistic/comma-dangle
  <Context, >(props: ListLayoutSideBarPanelProps<Context> & WithContentInnerProps<ListLayoutArgs<Context>> & Partial<ListLayoutArgs<Context>>) => {

    const { renderContent, context, bindings, title, extras } = props;

    const renderedExtras = useMemo(() => extras
      ? extras.map((ExtraComponent, index) => (
        <ExtraComponent key={index} context={context} bindings={bindings}/>
      ))
      : null, [extras, context, bindings]);

    return (
      <Container className={styles.footerPanel}>

        <h4>
          <span><Translate message={title}/></span>
          {renderedExtras}
        </h4>

        {renderContent({ context, bindings })}

      </Container>
    );
  }));

export const ListLayoutSideBarButton = withContent(React.memo(
  // eslint-disable-next-line @stylistic/comma-dangle
  <Context, >(props: ListLayoutSideBarButtonProps<Context> & WithContentInnerProps<ListLayoutArgs<Context>> & Partial<ListLayoutArgs<Context>>) => {
    const { renderContent, context, bindings } = props;
    return renderContent({ context, bindings });
  }));
