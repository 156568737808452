import React, { useCallback } from 'react';
import { useGetListItemFlag } from './useGetListItemFlag';
import { useReportsConfig } from 'modules/reports/providers/ReportsConfigProvider';
import { hasNoPrevValue } from '../../utils';
import messages from 'messages';
import { Feature, Product, Report, ReportMibiType, UserPermission, UserType } from 'interfaces/api';
import { HeaderMultiSelect, PopOvers } from 'components';
import { getPatientSubtitle } from 'modules/admin/users/utils';
import { faFilePdf, faStethoscope, faUser, faVial } from '@fortawesome/pro-light-svg-icons';
import * as Controls from 'modules/reports/components/Controls';
import { filter } from 'lodash';
import { ListLayoutHeader, useGuard } from 'containers';
import { ReportListContext } from 'modules/reports/index';

const pdfLabels = messages.reports.controls.pdf;

export const useHeader = () => {

  const guard = useGuard();
  const getReportListItemFlag = useGetListItemFlag();
  const { reportDisplay: { exportReports } } = useReportsConfig();

  return useCallback<ListLayoutHeader<ReportListContext, Report>>(({ data, context, bindings }) => {

    const reports = [data, ...(data.subreports || [])];
    const report = reports[context.selectedReport];

    const {
      patient,
      values,
      mibi,
      showCumulative,
      showPdf,
      showOriginal,
      showOriginalExclusive,
      showCumulativePdf,
      showCumulativeAllPdf,
      showSend,
      has_elpho,
      is_locked,
      status,
      tnr,
      is_unread,
      patientName,
    } = report;

    const hasPrevious = !hasNoPrevValue(values);

    const showGraphicalBDW = (hasPrevious && mibi !== ReportMibiType.Only) && guard({ feature: Feature.GraphicalBDW }, () => true);
    const showSetRead = is_unread && guard({ feature: Feature.ManualReadReports }, () => true);
    const showElpho = has_elpho && guard({ feature: Feature.Elpho }, () => true);
    const showNewOrder = guard({ product: Product.OA, feature: Feature.BdwNewOrder }, () => true);
    const showRealRerequest = report.orders?.length === 1
      && guard({ product: Product.OA, feature: Feature.RealRerequest, forbidden: [UserType.PAT] }, () => true)
      && report.orders[0].realReRequestConditions?.allowed;
    const showUnrealRerequest = !showRealRerequest
      && report.showRerequest
      && guard({ product: Product.OB, feature: Feature.UnrealRerequest, forbidden: [UserType.PAT] }, () => true);
    const reportAttachmentsFeature = guard({ feature: Feature.ReportAttachments }, () => true);

    const multiSelect: HeaderMultiSelect = reports?.length > 1
      ? {
        items: reports.map((r, idx) => ({
          id: idx,
          title: r.patient?.displayName || r.patientName,
          subtitle: `${r.tnr} - ${r.laborgruppe}`,
          flag: getReportListItemFlag(r),
        })),
        selected: context.selectedReport || 0,
        onSelect: idx => bindings.setContext({ selectedReport: idx }),
      }
      : undefined;

    return {
      multiSelect,
      title: patient?.displayName || patientName,
      subtitle: patient ? getPatientSubtitle(patient) : undefined,
      flag: getReportListItemFlag(report),
      popovers: filter([
        {
          label: status + ' ' + tnr,
          content: <PopOvers.Report report={report}/>,
          noShrink: true,
        },
        {
          label: report.doctor?.displayName,
          icon: faStethoscope,
          content: <PopOvers.Doctor doctor={report.doctor}/>,
        },
        report.laboratoryGroup
          ? {
            label: report.laboratoryGroup.name,
            icon: faVial,
            content: <PopOvers.LaboratoryGroup laboratoryGroup={report.laboratoryGroup}/>,
          }
          : {
            label: report.laboratory.displayName,
            icon: faVial,
            content: <PopOvers.Labor labor={report.laboratory}/>,
          },
        patient && {
          label: patient?.displayName,
          icon: faUser,
          content: <PopOvers.Patient patient={report.patient}/>,
        },
      ]),
      controls: [
        Controls.Pin,
        showNewOrder && Controls.NewOrder,
        showSetRead && Controls.SetRead,
        is_locked && Controls.Unlock,
        (exportReports && (showPdf || showOriginal || showOriginalExclusive || showCumulativePdf || showCumulativeAllPdf)) && {
          icon: faFilePdf,
          label: pdfLabels.label,
          controls: [
            showPdf && Controls.Pdf.Report,
            guard({ feature: Feature.PatientPdf, forbidden: UserType.PAT }, () => Controls.Pdf.Patient),
            showCumulativePdf && Controls.Pdf.Cumulative,
            showCumulativeAllPdf && Controls.Pdf.CumulativeAll,
            (showOriginal && !showOriginalExclusive) && guard({ feature: Feature.OriginalReport }, () => Controls.Pdf.Original),
            ...(report.originalPdfIds?.map((id, idx) => Controls.Pdf.OriginalAdditional(id, idx + 1)) || []),
            (showOriginalExclusive && !showOriginal) && guard({ feature: Feature.OriginalReportExclusive }, () => Controls.Pdf.OriginalExclusive),
          ],
        },
        (exportReports && showCumulative) && Controls.CumulativeControl,
        showGraphicalBDW && Controls.Graphic,
        showElpho && Controls.Elpho,
        showRealRerequest && Controls.RealReRequest,
        showUnrealRerequest && Controls.UnrealRerequest,
        (reportAttachmentsFeature && report.laboratory.reportDisplay?.reportAttachments) && Controls.Attachments,
        guard({ product: Product.OB, feature: Feature.ReportComments }, () => Controls.Comments),
        showSend && Controls.SendReport,
        (report.laboratory.reportDisplay?.contactLaboratory && (report.laboratoryGroup?.email || report.laboratory.email)) && Controls.ContactLaboratory,
        guard({
          permitted: [UserType.ADM, UserType.LAB, UserType.SAD, UserType.LAU],
          userPermission: { [UserType.LAU]: UserPermission.PermissionDeleteReport },
        }, () => Controls.Delete),
        guard({ permitted: [UserType.ARZ, UserType.LAB, UserType.PAT] }, () => Controls.SetUnread),
        guard({ product: Product.OB, feature: Feature.ReportAccessLog }, () => Controls.AccessLog),
        guard({ product: Product.OB, feature: Feature.ReportChangeLog }, () => Controls.ChangeLog),
      ],
      maxVisibleControls: 2 + filter([
        showSetRead,
        showElpho,
        (showPdf || showOriginal || showOriginalExclusive),
        is_locked,
        showCumulative,
        showGraphicalBDW,
        showNewOrder,
        showUnrealRerequest,
        showRealRerequest,
      ]).length,
    };
  }, [guard, getReportListItemFlag]);
};
