import { LanguageCode, Platform } from 'interfaces/api';
import { create } from 'zustand';
import { ConfigFile } from 'interfaces';
import { getPlatform } from 'utils/device';
import axios from 'axios';
import { Device } from '@capacitor/device';
import { createSelectors } from 'utils/helpers';
import { API_ENDPOINT_KEY } from 'providers/ApiProvider/useApiClient';

interface EnvState extends ConfigFile {
  platform: Platform;
  endpoint: string;
  locales: LanguageCode[];
  deviceLocale: LanguageCode;
  isWeb: boolean;
  isNative: boolean;
  isDev: boolean;
  isProd: boolean;
}

export const useEnvStore = create<EnvState>()(() => ({
  platform: undefined,
  locales: undefined,
  endpoint: undefined,
  deviceLocale: undefined,
  isDev: import.meta.env.DEV,
  isProd: import.meta.env.PROD,
  isWeb: undefined,
  isNative: undefined,
  BACKEND_URL: undefined,
  BUILD_NUMBER: undefined,
  BUILD_DATE: undefined,
  TARGET: undefined,
  PRIVACY_URL: undefined,
  TITLE: undefined,
  TITLE_SEPARATOR: undefined,
  APP_NAME: undefined,
  CHECK_WEBSOCKET_MAINTENANCE: undefined,
  REPORTS_GRAPH_NO_ORANGE: undefined,
  HERO_PAGE_FULL_SIZE_COVER: undefined,
  STATUS_BAR_DARK_MODE: undefined,
  HIDE_PROFILE_LABORATORY: undefined,
  I18N_LOCALES_SHORT: undefined,
  DISABLE_SENTRY: undefined,
  RECOVERY_URL: undefined,
  PREFERRED_COUNTRY: undefined,
  SSE_RECONNECT_SECONDS: undefined,
}));

export const initializeEnvStore = async () => {

  const platform = await getPlatform();
  const config: ConfigFile = (await axios.get('/config.json', { responseType: 'json' })).data;

  const locales = (config.I18N_LOCALES_SHORT || 'en,de').split(',') as LanguageCode[];

  const deviceLocale = [(await Device.getLanguageCode()).value].filter(l => locales.indexOf(l.split('-')[0] as LanguageCode) > -1)[0] as LanguageCode;

  useEnvStore.setState({
    platform,
    ...config,
    locales,
    deviceLocale,
    endpoint: localStorage.getItem(API_ENDPOINT_KEY) || config.BACKEND_URL,
    isWeb: platform === Platform.WEB,
    isNative: platform !== Platform.WEB,
  });

  return useEnvStore.getState();

};

// auto selectors
export const useEnv = createSelectors(useEnvStore).use;
