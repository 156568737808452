import { StoreProductUnitType } from 'interfaces/api';

export default {
  label: 'Products',
  tabs: {
    general: 'General',
    images: 'Images',
  },
  fields: {
    name: 'Product name',
    introduction: 'Introduction text',
    description: 'Product description',
    image: 'Product image',
    pzn: 'PZN',
    articleNumber: 'Article Number',
    category: 'Category',
    categories: 'Categories',
    addCategory: 'Add category',
    manufacturer: 'Manufacturer',
    price: 'Price',
    unitAmount: 'Units',
    included_doctors: 'Included doctors',
    excluded_doctors: 'Excluded doctors',
    isPublic: {
      label: 'Is public',
      description: 'You can add included or excluded doctors depending on public or private product.',
    },
    isActive: 'Is active',
    isInStock: 'Is in stock',
    needsDoctorManufacturer: 'Needs Doctor Manufacturer',
    unitTypes: {
      [StoreProductUnitType.Liter]: 'Liter',
      [StoreProductUnitType.Milliliter]: 'Milliliter',
      [StoreProductUnitType.Pieces]: 'Pieces',
    },
  },
  permissionType: {
    public: 'Public',
    private: 'Private',
  },
  controls: {
    add: {
      label: 'Add product',
    },
    edit: {
      label: 'Edit product',
    },
    delete: {
      label: 'Delete product',
      confirm: 'Confirm delete',
    },
  },
};
